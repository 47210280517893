<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">单位统计</a>
        </span>
      </div>
      <div class="framePage-body infoAll">
        <div class="operationControl searchboxfl">
          <div class="searchbox">
            <div title="行政区划" class="searchboxItem">
              <span class="itemLabel">行政区划:</span>
              <el-cascader
                v-model="selectDate.areaId"
                :options="areatreeList"
                clearable
                filterable
                :props="propsarea"
                size="small"
                @change="(val)=>{clean(val,'areaId')}"
              ></el-cascader>
            </div>
            <div title="单位名称" class="searchboxItem">
              <span class="itemLabel">单位名称:</span>
              <el-input
                v-model="selectDate.compName"
                type="text"
                size="small"
                placeholder="请输入单位名称"
                clearable
              />
            </div>
            <div title="单位类型" class="searchboxItem">
              <span class="itemLabel">单位类型:</span>
              <el-select
                @change="(val)=>{clean(val,'compType')}"
                v-model="selectDate.compType"
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in companytypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox" style="margin-top:15px">
            <div title="入驻时间" class="searchboxItem">
              <span class="itemLabel" style="width:90px">入驻时间:</span>
              <el-date-picker
                v-model="entrytime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                size="small"
                value-format="timestamp"
              ></el-date-picker>
            </div>
            <div class="df">
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
            </div>
          </div>
        </div>
        <div class="operationControl operationControl1">
          <div class="searchbox">
            <div class="searchboxItem">
              <span class="itemLabel">合计单位总数:</span>
              <span style="text-align:left">{{totalData.compTotalNum}}个</span>
            </div>
            <div class="searchboxItem">
              <span class="itemLabel">合计机构总数:</span>
              <span style="text-align:left">{{totalData.orgNum}}个</span>
            </div>
            <div class="searchboxItem">
              <span class="itemLabel">合计企业总数:</span>
              <span style="text-align:left">{{totalData.compNum}}个</span>
            </div>
            <div class="searchboxItem">
              <span class="itemLabel">合计开班总数:</span>
              <span style="text-align:left">{{totalData.projectNum}}个</span>
            </div>
            <div class="searchboxItem">
              <span class="itemLabel">合计培训学员总数:</span>
              <span style="text-align:left">{{totalData.projectUserNum}}人</span>
            </div>
            <div class="searchboxItem">
              <span class="itemLabel">合计结业学员总数:</span>
              <span style="text-align:left">{{totalData.graduationNum}}人</span>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
              <el-table-column
                label="单位名称"
                align="left"
                show-overflow-tooltip
                prop="compName"
                width="200"
              />
              <el-table-column
                label="开班区域"
                align="left"
                show-overflow-tooltip
                prop="areaNamePath"
                width="150"
              />
              <el-table-column
                label="开班总数"
                align="right"
                show-overflow-tooltip
                prop="openProjectSum"
              />
              <el-table-column
                label="培训学员总数"
                align="right"
                show-overflow-tooltip
                prop="projectUserSum"
              />
              <el-table-column
                label="已结业学员总数"
                align="right"
                show-overflow-tooltip
                prop="graduationSum"
              />
              <el-table-column
                label="培训课程总量"
                align="right"
                show-overflow-tooltip
                prop="projectCourseSum"
              ></el-table-column>
              <el-table-column
                label="累计培训总学时"
                align="right"
                show-overflow-tooltip
                prop="lessonNumTotal"
              ></el-table-column>
              <el-table-column label="入驻时间" align="left" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span>{{ scope.row.createTime | momentDate }}</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    style="padding:0 15px"
                    type="text"
                    size="mini"
                    @click="ClassopeningRecord(scope.row.compId,scope.row.compName,scope.row.areaId) "
                  >开班记录</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "UnitStatistics",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      selectDate: {
        areaId: "",
        compName: "",
        compType: "",
        endDateLong: "",
        startDateLong: ""
      },
      areatreeList: [], // 行政区划
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true
      },
      totalData: {},
      companytypeList: [], // 单位类型
      companytype: "",
      entrytime: "",
      search: {
        areaId: true,
        compType: true
      }
    };
  },
  created() {},
  mounted() {
    this.getTableHeight();
    this.getareatree();
    this.getcompanytypeList();
  },
  methods: {
    getData(pageNum = 1) {
      if (this.search.areaId) {
        this.selectDate.areaId = this.$route.query.areaId;
      }
      if (this.search.compType) {
        this.selectDate.compType = this.$route.query.params;
      }
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if (this.selectDate.areaId) {
        params.areaId = this.selectDate.areaId;
      }
      if (this.selectDate.compName) {
        params.compName = this.selectDate.compName;
      }
      if (this.selectDate.compType) {
        params.compType = this.selectDate.compType;
      }
      if (this.entrytime) {
        params.startDateLong = this.entrytime[0];
        params.endDateLong = this.entrytime[1];
      }
      this.doFetch({
        url: "/gov/project/queryOrgList",
        params,
        pageNum
      });
      this.$post("/gov/project/queryOrgStati", params).then(ret => {
        this.totalData = ret.data;
      });
    },
    clean(val, name) {
      this.selectDate[name] = val;
      this.search[name] = false;
    },
    getcompanytypeList() {
      const companytypelist = this.$setDictionary("COMPANYTYPE", "list");
      const list = [{ value: "", label: "全部" }];
      for (const key in companytypelist) {
        list.push({
          value: key,
          label: companytypelist[key]
        });
      }
      this.companytypeList = list;
    },
    //获取行政区划(监管端)
    getareatree() {
      this.$post("/gov/area/tree").then(ret => {
        this.areatreeList = ret.data;
      });
    },
    ClassopeningRecord(compId, compName, areaId) {
      this.$router.push({
        path: "/web/ClassTrainingStatistics",
        query: {
          compId,
          compName,
          areaId,
          needHold2: true
        }
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 100;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    }
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: function(val) {
      if (JSON.stringify(val.query) != "{}") {
        this.search.areaId = true;
        this.search.compType = true;
        this.selectDate.areaId = "";
        this.selectDate.compType = "";
        this.getData(-1);
      }
    }
  }
};
</script>
<style lang="less" scoped>
.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
</style>
<style lang="less">
.searchBox {
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
.infoAll {
  .operationControl {
    border: none;
  }
  .operationControl1 {
    border-top: 1px solid #f5f5f6;
    padding-top: 10px;
  }
}
// .el-table__fixed-body-wrapper {
//   margin-top: -23px;
// }
</style>
